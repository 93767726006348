









































































/**
 * All the deivces that have the status of archived are displayed below
 * the admin can delete the devices from the inventory list.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import TableHeader from "@/components/tableHeader.vue";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import { Device } from "@/interface/DeviceInterface";
import { ActionMessage } from "@/model/Messages/statusMessage";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "InventoryArchive",
  components: {
    TableHeader
  },
  computed: {
    ...mapGetters(["getAllArchivedDevices", "getUserAccess"])
  },
  methods: {
    ...mapActions(["getAllArchivedFromBackEnd", "DeleteArchivedDevice"])
  }
})
export default class InventoryArchive extends Mixins(confirmModal) {
  /*----------  Vuex  ----------*/
  private getAllArchivedFromBackEnd!: Function;
  private DeleteArchivedDevice!: Function;

  private getAllArchivedDevices!: Array<Device>;
  private readonly getUserAccess!: UserAccess;
  /*----------  Local data  ----------*/
  changeEventStatus: ActionMessage | null = null;
  loading = false;
  fields = [
    {
      key: "id",
      sortable: false,
      label: this.translateTableLabel("id")
    },
    {
      key: "articleNumber",
      sortable: false,
      label: this.translateTableLabel("articleNumber")
    },

    {
      key: "serialNumber",
      sortable: true,
      label: this.translateTableLabel("serialNumber")
    },
    {
      key: "type",
      sortable: false,
      label: this.translateTableLabel("type")
    },
    {
      key: "state",
      sortable: false,
      label: this.translateTableLabel("state")
    },
    {
      key: "createdAt",
      sortable: false,
      label: this.translateTableLabel("createdAt")
    },
    {
      key: "lastSeen",
      sortable: false,
      label: this.translateTableLabel("lastSeen")
    }
  ];

  /*----------  Vue life cycles  ----------*/
  created() {
    if (this.getUserAccess.Access.EDIT.inventory.archivedList.deleteDevice) {
      let acctions = {
        key: "actions",
        sortable: true,
        label: this.translateTableLabel("actions")
      };
      this.fields.push(acctions);
    }
    this.loading = true;
    this.getAllArchivedFromBackEnd().then(() => {
      this.loading = false;
    });
  }

  /*----------  Methods  ----------*/
  translateTableLabel(label: string) {
    return this.$t(`inventory.tableLabels.${label}`);
  }
  handleDeleteDevice(id: number) {
    this.changeEventStatus = null;
    this.confirm(this.$t("inventory.archived.modal.deleteDeviceContent").toString(), async () => {
      try {
        this.loading = true;
        let res = await this.DeleteArchivedDevice(id);
        if (res.status === 200) {
          this.changeEventStatus = {
            class: "success",
            msg: this.$t("inventory.archived.modal.deleteDeviceSuccess").toString()
          };
        } else {
          this.changeEventStatus = {
            class: "danger",
            msg: this.$t("inventory.archived.modal.deleteDeviceError").toString()
          };
        }
        this.loading = false;
      } catch (error) {
        this.changeEventStatus = {
          class: "danger",
          msg: error
        };
        this.loading = false;
      }
    });
  }
}
